import React, { useEffect, useState } from 'react';
import Table from '../components/Table';
import { Link, useParams, useLocation, Redirect, withRouter } from 'react-router-dom';
import useAxios from 'axios-hooks';
import { getHistoryFields, getLabelPlural, isRelationUUID, isRelationName, isRelationProperty, getRelName, getLabel, getRelationsInFilterable } from '../lib/labels.js';
import Loading from '../components/Loading';
import { filter, groupBy, isNull, isString, mergeWith, uniq, uniqBy } from 'lodash';
import { Typeahead } from 'react-bootstrap-typeahead';

const all_search_fields = {
	client: ['number', 'name', 'person', 'city', 'zip', 'street'],
	location: ['name', 'street', 'city'],
	instrument: ['room', 'model', 'manufacturer_serial_number'],
	person: ['first_name', 'last_name'],
	model: ['name'],
	brand: ['name'],
	user: ['name', 'email']
};

function isWideTable(resource) {
	return ['person', 'instrument'].includes(resource);
}

function FilterSelect({ filter, query_filters_map, data_list, handleFilter }) {
	return (
		<div className="form-group">
			<label htmlFor={filter}>{getLabelPlural(filter) + " Filter"}</label>
			<select value={query_filters_map[filter] ? query_filters_map[filter] : ""} className="form-control" id={filter} onChange={e => handleFilter(filter, e.target.value)}>
				<option key="blank" value="">-</option>
				{
					uniqBy(data_list.map(item => item[filter]), item => item.uuid).sort((a, b) => {
						if (a.name < b.name) {
							return -1;
						}
						if (a.name > b.name) {
							return 1;
						}
						return 0;
					}).map(item => (
						<option key={item.uuid} value={item.uuid}>{item.name}</option>)
					)
				}
			</select>
		</div>
	);
}

function FilterTypeahead({ filter, data_list, handleFilter, query_filters_map }) {
	const squashed_options = data_list.reduce((res, item) => {
		const value = item[filter];
		if (!value) {
			return res;
		}
		if (value.name) {
			res.push(value);
			return res;
		}
		// check if array and then add each
		if (Array.isArray(value)) {
			console.log('value array', value);
			value.forEach(val => {
				if (val.name !== null) {
					res.push(val);
				}
			});
			return res;
		}
		return res;
	}, []);
	const options = uniqBy(
		// data_list
		// .map(item => {
		// 	//console.log('item', item[filter]);
		// 	return item[filter];
		// })
		// .filter(item => item && item.name !== null)
		squashed_options,
		item => item.uuid).sort((a, b) => {
			if (a.name < b.name) {
				return -1;
			}
			if (a.name > b.name) {
				return 1;
			}
			return 0;
		});
	function handleChange(value) {
		if (value.length <= 0) {
			return;
		}
		const found = options.filter(opt => {
			return opt.name === value[0].name;
		});
		if (found.length !== 1) {
			return;
		}
		const uuid = found[0].uuid;
		handleFilter(filter, uuid);
	}
	function handleEmpty(e) {
		if (!e.target || e.target.value !== "") {
			return;
		}
		handleFilter(filter, "");
	}
	console.log('filters map', query_filters_map);
	const selected_value = query_filters_map[filter];
	let selected = [];
	if (selected_value) {
		selected = options.filter(opt => {
			return opt.uuid === selected_value;
		});
	}
	console.log('selected', selected);
	return (
		<div className="form-group">
			<label htmlFor={filter}>{getLabelPlural(filter) + " Filter"}</label>
			<Typeahead
				id={filter}
				onChange={handleChange}
				onBlur={handleEmpty}
				options={options}
				labelKey="name"
				placeholder="Auswählen..."
				defaultSelected={selected}
			/>
		</div>
	);
}

function ResourceList({ history, role, is_super, user_dispatch }) {
	const { resource } = useParams();
	const query = new URLSearchParams(useLocation().search);
	const data_history = query.get('history');
	const data_archive = query.get('archive');
	const now = new Date();
	const valid_date = query.get('valid_date') || `${now.getFullYear()}-${(now.getMonth() + 1).toString().padStart(2, '0')}-${now.getDate().toString().padStart(2, '0')}`;

	//console.log('valid_date', valid_date);

	const all_filter_selects = getRelationsInFilterable()[resource]
		? getRelationsInFilterable()[resource].filter(fname => {
			//console.log("filter filter", role, fname);
			return true;
			return !(role === 'client' && fname === 'client');
		})
		: getRelationsInFilterable()[resource];

	let query_filters_map = all_filter_selects ? all_filter_selects
		.reduce((res, fname) => {
			const value = query.get(fname);
			if (value) {
				res[fname] = value;
			}
			return res;
		}, {}) : {};

	const resource_url = (data_archive === null
		? `/json/${resource}?${(new URLSearchParams({ "valid_date": valid_date }).toString())}`
		: `/json/${resource}?archive=true`);
	console.log('resource_url', resource_url);
	const [{ data, loading, error }, refetch] = useAxios(resource_url, { useCache: false });

	useEffect(() => {
		console.log('refetch');
		refetch();
	}, [resource_url]);

	const [searchTerm, setSearchTerm] = useState("");

	const [attributeFilter, setAttributeFilter] = useState({});

	if (loading) {
		return (
			<Loading />
		);
	}

	if (error) {
		if (error.response.status === 401) {
			user_dispatch({ type: 'logged-out' });
			return null;
		}
		return <React.Fragment><p>Error</p><pre>{JSON.stringify(error)}</pre></React.Fragment>
	}

	let data_list = data.payload.map(row => {
		if (!isNull(data_history)) {
			return row;
		}
		const entries = Object.entries(row);
		const [fields, relations] = _.partition(entries, entry => {
			return !isRelationUUID(entry[0]) && !isRelationName(entry[0]) && !isRelationProperty(entry[0]);
		});
		const relations_grouped = relations.reduce((grpd, entry) => {
			const rel_name = getRelName(entry[0]);
			if (!grpd.hasOwnProperty(rel_name)) {
				grpd[rel_name] = {};
			}
			if (isRelationUUID(entry[0])) {
				grpd[rel_name].uuid = entry[1];
			} else if (isRelationName(entry[0])) {
				grpd[rel_name].name = entry[1];
			} else if (isRelationProperty(entry[0])) {
				// TODO
				grpd[rel_name].property = entry[1];
			}
			return grpd;
		}, {});
		const fields_grouped = fields.reduce((grpd, entry) => {
			grpd[entry[0]] = entry[1];
			return grpd;
		}, {});
		return { ...fields_grouped, ...relations_grouped };
	});

	let headers = data_list.length <= 0 ? [] : Object.keys(data_list[0]);

	// keep / remove history headers
	if (data_history === null) {
		const history_headers = getHistoryFields();
		headers = headers.filter(header => {
			return !(history_headers.includes(header));
		});
	}

	// remove view specific headers
	const header_blacklist = {
		person: ['middle_name', 'notes_internal', 'name', 'employed', 'count_relations_in'],
		client: ['street', 'zip', 'city', 'phone', 'count_relations_in'],
		location: ['street', 'zip', 'city', "notes_internal", 'count_relations_in'],
		instrument: ['year_of_manufacture', 'field_of_application', 'dc_system_date_of_installation', 'count_relations_in'],
		report: ['tuning_period_in_months', 'notes', 'notes_internal', 'tuning_done', "tone_pitch_on_arrival", "tone_pitch_tuned", "dc_service_done", "temperature_in_grad_celcius", "humidity_in_percent", "checklist", "condition_of_surface", "recommendations", 'count_relations_in'],
		offer: ['location', 'client', 'count_relations_in'],
		brand: ['count_relations_in'],
		model: ['count_relations_in'],
		finish2: ['count_relations_in'],
	};

	const resource_header_blacklist = header_blacklist[resource];

	if (resource_header_blacklist) {
		headers = headers.filter(header => {
			return !resource_header_blacklist.includes(header);
		});
	}

	const user_header_whitelist = {
		client: ['number', 'name', 'person'],
		location: role !== 'client' ? ['name', 'person', 'client', "documents"] : ['name', 'person', 'client'],
		instrument: role !== 'client' ? ['instrument_type', 'model', 'finish2', 'manufacturer_serial_number', 'room', 'location', 'client', 'affiliation_type', 'documents', 'status', 'report', 'person', 'tuning_period_in_months'] : ['instrument_type', 'model', 'finish2', 'manufacturer_serial_number', 'room', 'location', 'client', 'status', 'report'],
		offer: ['offer_number', 'status', 'instrument', 'recommendations', 'amount', 'date', 'pdf', 'report'],
		user: ['name', 'email', 'role', 'client', 'reset_link']
	};

	if (!is_super && user_header_whitelist[resource]) {
		headers = user_header_whitelist[resource];
	}

	// search
	const search_fields = all_search_fields[resource];

	let data_list_searched = [];

	if (searchTerm.length > 0) {
		data_list_searched = data_list.filter(row => {
			return search_fields.reduce((res, field) => {
				//console.log('row', row, 'field', field, 'search', searchTerm);
				return res
					|| (row[field] !== null && isString(row[field]) && row[field].toLowerCase().includes(searchTerm.toLowerCase()))
					|| (row[field] !== null && row[field].hasOwnProperty('name') && isString(row[field].name) && row[field].name.toLowerCase().includes(searchTerm.toLowerCase()));
			}, false);
		});
	} else {
		data_list_searched = [...data_list];
	}

	// relational filters
	const filter_selects = all_filter_selects;

	const handleFilter = (fname, value) => {
		query_filters_map[fname] = value;
		if (value == "") {
			delete query_filters_map[fname];
		}
		const redirect_description = {
			pathname: `/resource/${resource}`,
			search: "?" + new URLSearchParams({ ...query_filters_map, "valid_date": valid_date }).toString()
		};
		history.push(redirect_description);
	};

	let data_list_filtered;

	// relational filter
	const filter_keys = Object.keys(query_filters_map);
	if (filter_keys.length > 0) {
		data_list_filtered = data_list_searched.filter(row => {
			return filter_keys.reduce((res, filter_key) => {
				//console.log('row', row);
				return res && row[filter_key].uuid == query_filters_map[filter_key];
			}, true);
		});
	} else {
		data_list_filtered = data_list_searched.map(x => x);
	}

	// attribute filter
	data_list_filtered = data_list_filtered.filter(row => {
		//console.log('attr filter row', row);
		return Object.entries(attributeFilter).reduce((keep, filter_pair) => {
			//console.log('attr filter pair', filter_pair);
			return keep && row[filter_pair[0]] === filter_pair[1];
		}, true);
	});

	const attribute_filters = resource === 'offer' ? [
		{
			name: 'status',
			options: [
				{ value: 'ok', label: '' },
				{ value: 'plannable', label: '' },
				{ value: 'urgent', label: '' },
			],
		},
		{
			name: 'offer_status',
			options: [
				{ value: 'open', label: '' },
				{ value: 'archived', label: '' },
			],
		}
	] : [];

	const has_valid_date_picker = resource !== 'offer' && ['super', 'admin'].includes(role);

	//console.log(data_list);
	function changeValidDate(e) {
		e.preventDefault();
		let chosen_date = e.target['valid-date'].value;
		if (chosen_date.length < 1) {
			const now = new Date();
			chosen_date = `${now.getFullYear()}-${(now.getMonth() + 1).toString().padStart(2, '0')}-${now.getDate().toString().padStart(2, '0')}`;
		}
		const redirect_description = {
			pathname: `/resource/${resource}`,
			search: "?" + new URLSearchParams({ ...query_filters_map, "valid_date": chosen_date }).toString()
		};
		history.push(redirect_description);
	}

	if (resource === 'user' || resource === 'location') {
		const data_list_filtered_grouped = groupBy(data_list_filtered, row => {
			return row.id;
		});

		//console.log('grouped by id', data_list_filtered_grouped);

		const data_list_merged = Object.entries(data_list_filtered_grouped).map(([_, group]) => {
			if (group.length <= 1) {
				return group[0];
			}
			return mergeWith.apply(null, group.concat((objValue, srcValue, key, obj, source) => {
				//console.log('mergewith', objValue, srcValue, key, obj, source);
				if ((resource === 'user' && key === 'client') || (resource === 'location' && key === 'person')) {
					return [srcValue].concat(objValue);
				}
				return;
			}));
		});

		//console.log('merge with', data_list_merged);

		data_list_filtered = [...data_list_merged];
	}

	// console.log('data_list', data_list);
	// console.log('length', data_list.length);

	return (
		<section>
			<header className="bg-grayl py-3 d-flex mb-2">
				<div className="container d-md-flex">
					<section className="flex-grow-1">
						<h1 className="subtitle d-inline-block pt-2">{getLabelPlural(resource)}</h1>
						{
							['admin', 'super'].includes(role) && <Link className="ml-2 btn btn-success new-button" to={`/resource/${resource}/form`}>Neu</Link>
						}
						<div>
							<div className="form-group">
								{is_super && <div className="btn-group mr-2" role="group">
									<a className={"btn btn-secondary" + (data_history === null ? ' active' : '')} href={`/resource/${resource}`}>
										aktuell
									</a>
									<a className={"btn btn-secondary" + (data_history === null ? '' : ' active')} href={`/resource/${resource}?history`}>
										historisch
									</a>
								</div>}
							</div>
							{
								search_fields && <div className="form-group">
									<div className="input-group mb-3">
										<div className="input-group-prepend">
											<span className="input-group-text" id="search-addon">Suche</span>
										</div>
										<input
											type="text"
											value={searchTerm}
											onChange={e => setSearchTerm(e.target.value)}
											className="form-control"
											aria-label="Suche"
											aria-describedby="basic-addon1"
										/>
									</div>
								</div>
							}
							{
								!data_archive && filter_selects && filter_selects.map(filter => {
									return (
										<FilterTypeahead
											key={filter}
											filter={filter}
											data_list={data_list}
											handleFilter={handleFilter}
											query_filters_map={query_filters_map}
										/>
									);
								})
							}
							{
								attribute_filters && attribute_filters.map(filter => {
									return (
										<div key={filter.name} className="form-group">
											<label htmlFor={filter.name}>{getLabel(filter.name) + " Filter"}</label>
											{/* <Typeahead
												id={filter}
												onChange={handleChange}
												onBlur={handleEmpty}
												options={options}
												labelKey="name"
												placeholder="Auswählen..."
												defaultSelected={selected}
											/> */}
											<select className="form-control" name={filter.name} onChange={e => {
												//console.log(e.target.value);
												if (e.target.value === "") {
													delete attributeFilter[filter.name];
												} else {
													attributeFilter[filter.name] = e.target.value;
												}
												setAttributeFilter({ ...attributeFilter });
											}}>
												<option value="">-</option>
												{
													filter.options.map(opt => {
														return <option key={opt.value} value={opt.value}>{getLabel(opt.value)}</option>;
													})
												}
											</select>
										</div>
									);
								})
							}
						</div>
						{is_super && <ul className="mb-0 legend">
							<li className="text-success"><span className="text-darker">Erstellt</span></li>
							<li className="text-primary"><span className="text-darker">Bearbeitet</span></li>
							<li className="text-danger"><span className="text-darker">Gelöscht</span></li>
						</ul>}
					</section>
					<section className="flex-grow-1 header-spacer">
						{
							has_valid_date_picker && (
								<>
									{
										!data_archive && <form onSubmit={changeValidDate} className="d-flex justify-content-md-end">
											<div className="form-group">
												<label htmlFor="valid-date">Gültigkeits-Datum</label>
												<div className="input-group">
													<input
														name="valid-date"
														type="date"
														className="form-control date-picker-width"
														defaultValue={valid_date}
													/>
													<div className="input-group-append">
														<button className="btn btn-secondary" type="submit">Filtern</button>
													</div>
												</div>
											</div>
										</form>

									}
									<div className="d-flex justify-content-md-end">
										{
											data_archive
												? <a href={`/resource/${resource}`} className="btn btn-primary">Aktuell</a>
												: <a href={`/resource/${resource}?archive=true`} className="btn btn-secondary">Archiv</a>
										}
									</div>
								</>
							)
						}
					</section>
				</div>
			</header>
			<main>
				<div className={isWideTable(resource) ? "container-fluid" : "container"}>
					{
						headers.length > 0 && data_list_filtered.length > 0
							? <Table headers={headers} data={data_list_filtered} role={role} is_super={is_super} />
							: <p>Keine Einträge gefunden!</p>
					}
				</div>
			</main>
		</section>
	);
}

export default withRouter(ResourceList);