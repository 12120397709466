import React, { useEffect, useState } from 'react';
import { useParams, Link, Redirect, withRouter, useLocation } from 'react-router-dom';
import useAxios from 'axios-hooks';
import Loading from '../components/Loading.js';
import Home from './Home.js';
import { getMessage } from '../lib/labels.js';

function Login({ message_dispatch, message_state, user, user_dispatch }) {
    const [{ data, loading, error }, sendData] = useAxios({
        url: '/json/login',
        method: 'POST'
    }, { manual: true });

    const query = new URLSearchParams(useLocation().search);
    const email = query.get('email');

    const [fields, setFields] = useState({
        'email': email || '',
        'password': '',
    });

    useEffect(() => {
        if (data && data.user) {
            user_dispatch({ type: 'checked-in', payload: data });
        }
    }, [data]);

    const handleMessageClose = (e) => {
        e.preventDefault();
        message_dispatch({ type: 'close' })
    };

    if (loading || user.loading) {
        return <Loading />;
    } else if (error || user.error) {
        // todo
    } else if (user.data) {
        if (user.data.user && user.data.user !== null) {
            //message_dispatch({ type: 'push', payload: { status: 'ok', msg: data.msg } });
            return <Redirect to="/" />;
        }
    }
    return (
        <main className="d-flex justify-content-center">
            <section className="mt-5 w-login">
                <header>
                    <div className="logo-wrapper mx-auto mb-5"><img src="/img/logo.svg" /></div>
                    <h3>Login</h3>
                    {
                        message_state.messages.map((payload, key) => (
                            <div key={key} className={"alert alert-" + (payload.status === 'ok' ? 'primary' : 'danger') + (message_state.show && key === message_state.messages.length - 1 ? ' show' : '')} role="alert">
                                {payload.msg.toString()}
                                <button onClick={handleMessageClose} type="button" className="ml-2 mb-1 close" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                        ))
                    }
                </header>
                <main>
                    <form
                        onSubmit={
                            async e => {
                                e.preventDefault();
                                //console.log(e.target.email, e.target.password);
                                const result = await sendData({
                                    data: {
                                        email: e.target.email.value,
                                        password: e.target.password.value,
                                    }
                                });
                                // const result = await fetch('/json/login', {
                                //     method: 'POST',
                                //     headers: {
                                //         'Accept': 'application/json',
                                //         'Content-Type': 'application/json',
                                //         'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
                                //     },
                                //     body: JSON.stringify({
                                //         email: e.target.email.value,
                                //         password: e.target.password.value,
                                //     }),
                                // });
                                // const data = await result.json();
                                // user_dispatch({ type: 'checked-in', payload: data });
                                if (result.data.user && result.data.user !== null) {
                                    // fine
                                } else {
                                    message_dispatch({ type: 'push', payload: { status: (result.data.user && result.data.user !== null ? 'ok' : 'error'), msg: result.data.msg } });
                                }
                            }
                        }
                    >
                        <div className="form-group">
                            <label htmlFor="email">E-Mail</label>
                            <input type="email" id="email" name="email" className="form-control" value={fields.email} onChange={e => setFields({ ...fields, email: e.target.value })} />
                        </div>
                        <div className="form-group">
                            <label htmlFor="password">Passwort</label>
                            <input type="password" id="password" name="password" className="form-control" />
                        </div>
                        <button type="submit" className="btn btn-primary">Anmelden</button>
                    </form>
                    <div className="mt-2">
                        <Link to={`/forgot-password?email=${fields.email}`}>Passwort vergessen?</Link>
                    </div>
                </main>
            </section>
        </main>
    );
}

export default withRouter(Login);