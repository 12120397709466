import React from 'react';

export default function Loading() {
    return (
        <section className="d-flex h-100 justify-content-center align-items-center">
            <div className="spinner-border" role="status">
                <span className="sr-only">Laden...</span>
            </div>
        </section>
    )
}