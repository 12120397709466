import React from 'react';
import { useParams, Link, Redirect } from 'react-router-dom';
import useAxios from 'axios-hooks';
import { getName, getLabel } from '../lib/labels';
import Loading from '../components/Loading';

export default function ResourceDelete({message_dispatch, explicit_resource, user_dispatch}) {
    let { resource, id, db_id } = useParams();
    resource = resource || explicit_resource;
    id = id || db_id;
    const [{ data, loading, error }, deleteResource] = useAxios(
        {
            url: `/json/${resource}/${db_id}`,
            method: 'delete'
        },
        { useCache: false, manual: true }
    );
    if (loading) {
        return (
            <Loading />
		);
    }
    if (data) {
        //console.log(data);
        if (data.payload.redirect) {
            return <Redirect to={data.payload.redirect} />
        }
        return <Redirect to={`/resource/${resource}`} />
    }
    if (error) {
		if (error.response.status === 401) {
			user_dispatch({type: 'logged-out'});
            return null;
        }
		return <React.Fragment><p>Error</p><pre>{JSON.stringify(error)}</pre></React.Fragment>
	}
    return (
        <section className="container my-4">
            <form
                onSubmit={async e => {
                    e.preventDefault();
                    try {
                        const result = await deleteResource();
                        message_dispatch({type: 'push', payload: {status: 'ok', msg: result.data.msg}});
                    } catch(e) {
                        message_dispatch({type: 'push', payload: {status: 'error', msg: e}});
                    }
                }}
            >
                <h1 className="subtitle">{getLabel(resource) + " Löschen"}</h1>
                <Link className="btn btn-primary" to={`/resource/${resource}/${id}`}>Zurück</Link>
                <button className="btn btn-danger ml-2">Löschen Bestätigen</button>
            </form>
        </section>
    );
}