import React from 'react';
import { useParams, Redirect, withRouter, useLocation, Link } from 'react-router-dom';
import useAxios from 'axios-hooks';
import Loading from '../components/Loading.js';
import { getMessage } from '../lib/labels.js';

function ResetPassword({ message_dispatch, message_state }) {

    const { token } = useParams();
    const query = new URLSearchParams(useLocation().search);
    const email = query.get('email');

    const [{ data: check_data, loading: check_loading, error: check_error }, refetch] = useAxios({
        url: `/json/check-reset-password?token=${token}&email=${email}`,
        method: 'GET',
    }, { useCache: false });

    const [{ data, loading, error }, sendData] = useAxios({
        url: '/json/reset-password',
        method: 'POST'
    }, { manual: true });

    console.log('resetting', token, email);

    const handleMessageClose = (e) => {
        e.preventDefault();
        message_dispatch({ type: 'close' })
    };

    if ((check_data && !check_data.valid) || (check_error)) {
        return (
            <main className="d-flex justify-content-center">
                <section className="mt-5 w-login">
                    <header>
                        <div className="logo-wrapper mx-auto mb-5"><img src="/img/logo.svg" /></div>
                        <h3>Passwort zurücksetzen</h3>
                    </header>
                    <main>
                        <div className={"alert alert-danger show"} role="alert">
                            Diese Beantragung ist abgelaufen. <Link to={`/forgot-password?email=${email}`}>Beantragen sie hier eine neue E-Mail.</Link>
                        </div>
                    </main>
                </section>
            </main>
        );
    }

    if (loading || check_loading) {
        return <Loading />;
    } else if (error) {
        console.log('error', error);
        return null;
    } else if (data) {
        return <Redirect to={`/login?email=${email}`} />;
    }
    return (
        <main className="d-flex justify-content-center">
            <div className="messages pl-2 pt-2">
                {
                    message_state.messages.map((payload, key) => (
                        <div key={key} className={"position-absolute toast " + (message_state.show && key === message_state.messages.length - 1 ? 'show' : '')} role="alert" aria-live="assertive" aria-atomic="true">
                            <div className="toast-header">
                                <div className={'message-color rounded mr-1 ' + (payload.status === 'ok' ? 'bg-info' : 'bg-warning')}></div>
                                <strong className="mr-auto">{getMessage(payload.status)}</strong>
                                {/* <small>system</small> */}
                                <button onClick={handleMessageClose} type="button" className="ml-2 mb-1 close" data-dismiss="toast" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="toast-body">
                                {payload.msg.toString()}
                            </div>
                        </div>
                    ))
                }
            </div>
            <section className="mt-5 w-login">
                <header>
                    <div className="logo-wrapper mx-auto mb-5"><img src="/img/logo.svg" /></div>
                    <h3>Passwort zurücksetzen</h3>
                </header>
                <main>
                    <form
                        onSubmit={
                            async e => {
                                e.preventDefault();
                                //console.log(e.target.email, e.target.password);
                                const result = await sendData({
                                    data: {
                                        email: e.target.email.value,
                                        password: e.target.password.value,
                                        token: token,
                                    }
                                });
                                message_dispatch({ type: 'push', payload: { status: (true ? 'ok' : 'error'), msg: result.data.msg } });
                            }
                        }
                    >
                        <div className="form-group">
                            <label htmlFor="email">E-Mail</label>
                            <input type="email" id="email" name="email" className="form-control" defaultValue={email} />
                        </div>
                        <div className="form-group">
                            <label htmlFor="password">Passwort</label>
                            <input type="password" id="password" name="password" className="form-control" />
                        </div>
                        <button type="submit" className="btn btn-primary">Passwort zurücksetzen</button>
                    </form>
                </main>
            </section>
        </main>
    );
}

export default withRouter(ResetPassword);