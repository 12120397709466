import React, { useEffect, useState } from 'react';
import { useParams, Link, Redirect, withRouter, useLocation } from 'react-router-dom';
import useAxios from 'axios-hooks';
import Loading from '../components/Loading.js';
import { getMessage } from '../lib/labels.js';

function ForgotPassword({ message_dispatch, message_state }) {
    const [{ data, loading, error }, sendData] = useAxios({
        url: '/json/forgot-password',
        method: 'POST'
    }, { manual: true });

    const query = new URLSearchParams(useLocation().search);
    const email = query.get('email');

    const [fields, setFields] = useState({
        'email': email || '',
        'password': '',
    });

    const handleMessageClose = (e) => {
        e.preventDefault();
        message_dispatch({ type: 'close' });
    };

    useEffect(() => {
        if (data && data.msg && data.error) {
            message_dispatch({ type: 'push', payload: { status: 'error', msg: data.msg } });
        } else {
            message_dispatch({ type: 'close' });
        }
    }, [data]);

    if (loading) {
        return <Loading />;
    } else if (error) {
        console.warn('error', error);
        return null;
    } else if (data) {
        if (data) {
            //message_dispatch({ type: 'push', payload: { status: 'ok', msg: data.msg } });
            //
            if (!data.error) {
                return (
                    <main className="d-flex justify-content-center">
                        <section className="mt-5 w-login">
                            <header>
                                <div className="logo-wrapper mx-auto mb-5"><img src="/img/logo.svg" /></div>
                                {/* <h3>Password Änderung wurde Beantragt</h3> */}
                            </header>
                            <main>
                                <p>Danke! Überprüfen Sie Ihre E-Mail auf einen Bestätigungslink und rufen Sie dann die <Link to={`/login?email=${fields.email}`}>Anmelde-Seite</Link> auf.</p>
                                <p>Der Bestätigungslink ist eine Stunde gültig.</p>
                                <p>Sie können dieses Fenster schliessen.</p>
                            </main>
                        </section>
                    </main>
                );
            }
        }
    }
    return (
        <main className="d-flex justify-content-center">
            <section className="mt-5 w-login">
                <header>
                    <div className="logo-wrapper mx-auto mb-5"><img src="/img/logo.svg" /></div>
                    <h3>Passwort zurücksetzen</h3>
                    {
                        message_state.messages.map((payload, key) => (
                            <div key={key} className={"alert alert-" + (payload.status === 'ok' ? 'primary' : 'danger') + (message_state.show && key === message_state.messages.length - 1 ? ' show' : '')} role="alert">
                                {payload.msg.toString()}
                                <button onClick={handleMessageClose} type="button" className="ml-2 mb-1 close" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                        ))
                    }
                </header>
                <main>
                    <form
                        onSubmit={
                            async e => {
                                e.preventDefault();
                                //console.log(e.target.email, e.target.password);
                                const result = await sendData({
                                    data: {
                                        email: e.target.email.value,
                                    }
                                });
                                //message_dispatch({ type: 'push', payload: { status: (result.data.user && result.data.user !== null ? 'ok' : 'error'), msg: result.data } });
                            }
                        }
                    >
                        <div className="form-group">
                            <label htmlFor="email">E-Mail</label>
                            <input type="email" id="email" name="email" className="form-control" value={fields.email} onChange={e => setFields({ ...fields, email: e.target.value })} />
                        </div>
                        <button type="submit" className="btn btn-primary">Neues Passwort beantragen</button>
                    </form>
                    <div className="mt-2">
                        <Link to={`/login?email=${fields.email}`}>Zum Login</Link>
                    </div>
                </main>
            </section>
        </main>
    );
}

export default withRouter(ForgotPassword);