import React from 'react';
import { useParams, Link, Redirect, withRouter } from 'react-router-dom';
import useAxios from 'axios-hooks';
import Loading from '../components/Loading.js';

function Register() {
    const [{ data: get_data, loading: get_loading, error: get_error }, getData] = useAxios({
        url: '/json/register',
        method: 'GET',
    }, { useCache: false });
    const [{ data, loading, error }, sendData] = useAxios({
        url: '/json/register',
        method: 'POST'
    }, { manual: true });

    if (get_loading) {
        return <Loading />;
    } else if (get_error) {
        console.log(get_error);
    }

    const clients = get_data.payload;

    if (loading) {
        return <Loading />;
    } else if (error) {
        // todo
    } else if (data) {
        console.log(data);
    }
    return (
        <main className="d-flex justify-content-center">
            <section className="mt-5">
                <header>
                    <div className="logo-wrapper mx-auto mb-5">
                        <Link to="/"><img src="/img/logo.svg" /></Link>
                    </div>
                    <h3>Registrieren</h3>
                </header>
                <main>
                    <form
                        onSubmit={
                            async e => {
                                e.preventDefault();
                                //console.log(e.target.email, e.target.password);
                                const result = await sendData({
                                    data: {
                                        name: e.target.name.value,
                                        email: e.target.email.value,
                                        password: e.target.password.value,
                                        client_uuid: e.target.client_uuid.value || null,
                                        role: e.target.role.value || 'client',
                                    }
                                });
                            }
                        }
                    >
                        <div className="form-group">
                            <label htmlFor="name">Name*</label>
                            <input required type="text" id="name" name="name" className="form-control" />
                        </div>
                        <div className="form-group">
                            <label htmlFor="email">E-Mail*</label>
                            <input required type="email" id="email" name="email" className="form-control"/>
                        </div>
                        <div className="form-group">
                            <label htmlFor="password">Password*</label>
                            <input required type="password" id="password" name="password" className="form-control"/>
                        </div>
                        <div className="form-group">
                            <label htmlFor="role">Rolle*</label>
                            <select required id="role" name="role" className="form-control">
                                <option value="client">Kunde</option>
                                <option value="employee">Angestellt</option>
                                <option value="admin">Admin</option>
                            </select>
                        </div>
                        <div className="form-group">
                            <label htmlFor="client_uuid">Kunde</label>
                            <select id="client_uuid" name="client_uuid" className="form-control">
                                <option key="default" value={""}>{"-"}</option>
                                {
                                    clients && clients.map(client => {
                                        return <option key={client.entity_uuid} value={client.entity_uuid}>{client.name + ", " + client.number}</option>;
                                    })
                                }
                            </select>
                        </div>
                        <button type="submit" className="btn btn-primary">Registrieren</button>
                    </form>
                </main>
            </section>
        </main>
    );
}

export default withRouter(Register);