import React, { useState, useEffect, useRef } from 'react';
import useAxios from 'axios-hooks';
import { Redirect } from 'react-router-dom';
import { Typeahead } from 'react-bootstrap-typeahead';
import { groupBy, partition, set, uniqueId } from 'lodash';
import { getLabel, menschifyDateSwiss } from '../lib/labels';

function parse_into_relational_options(grouped_options, target_date) {
    let options = Object.entries(grouped_options).map(entity_group => {
        const valid_group = entity_group[1].filter(opt => {
            return opt.meta.valid_from <= target_date && (opt.meta.valid_to === null || opt.meta.valid_to >= target_date);
        });
        //console.log('internal', entity_group, valid_group);
        if (valid_group.length >= 1) {
            return valid_group[0];
        }
        const fallback = entity_group[1][0];
        return {
            ...fallback,
            label: fallback.meta.fallback_label
        };
    });
    // mutate options so our text values are unique
    const option_labels = options.map(opt => opt.label);
    options = options.map((opt, idx) => {
        //console.log('find', opt.value, option_labels.slice(0, idx).concat(option_labels.slice(idx+1)));
        if (option_labels.slice(0, idx).concat(option_labels.slice(idx + 1)).includes(opt.label)) {
            const fixed_label = `${opt.label} [${opt.value}]`;
            console.log('gotcha', fixed_label);
            opt.label = fixed_label;
            return opt;
        } else {
            return opt;
        }
    }, []);

    return options;
}

function getValidTime(valid_time, key) {
    //console.log(valid_time);
    if (valid_time === null || !valid_time) {
        return '∞';
    }
    if (key === 'valid_from') {
        const now = new Date();
        const today_string = `${now.getFullYear()}-${(now.getMonth() + 1).toString().padStart(2, '0')}-${now.getDate().toString().padStart(2, '0')}`;
        if (today_string === valid_time) {
            return 'Heute';
        }
    }
    return menschifyDateSwiss(valid_time);
}

function FileInput({ field, onChange, temporalHook }) {
    const has_default = field.attributes.defaultValue && field.attributes.defaultValue !== null;
    console.log('file input', field, has_default);
    const [upload_open, setUploadOpen] = useState(!has_default);
    let default_file = "";
    if (has_default) {
        default_file = field.attributes.defaultValue;
        //field.attributes.defaultValue = null;
    }
    return (
        <>
            {
                has_default && (
                    <div className="mb-1">
                        <div><a href={default_file.url} target="_blank">herunterladen</a></div>
                        <button className="btn btn-sm btn-secondary" type="button" onClick={e => { e.preventDefault; setUploadOpen(!upload_open) }}>
                            {!upload_open ? `ersetzen` : `nicht ersetzen`}
                        </button>
                    </div>
                )
            }
            {
                upload_open
                    ? (
                        <field.Tag
                            name={field.name}
                            className="form-control-file"
                            onChange={onChange}
                            onBlur={temporalHook}
                            accept="application/pdf"
                            {
                            ...(field.help && {
                                "aria-describedby": field.name + "_help"
                            })
                            }
                            {...({ ...field.attributes, defaultValue: "" })}
                        />
                    )
                    : (<input type="hidden" name={field.name} defaultValue={JSON.stringify(default_file)} />)
            }
        </>
    );
}

function getCookie(name) {
    // const value = `; ${document.cookie}`;
    // const parts = value.split(`; ${name}=`);
    // if (parts.length === 2) return parts.pop().split(';').shift();

    const match = document.cookie.match(new RegExp('(^|;\\s*)(' + name + ')=([^;]*)'));
    return (match ? decodeURIComponent(match[3]) : null);
}

function DocumentItem({ doc, removeDoc, changeDoc }) {
    //const name_pattern = '^(?!' + names.map(name => name.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')).join('|') + ')([A-Za-z0-9_\.-]+)$';
    return (
        <article className='card'>
            <div className='card-body'>
                {
                    doc.url
                        ? <p className='card-title'><a href={doc.url} target="_blank">{doc.file_name || 'Link'}</a></p>
                        : <p className='card-title'><a href={doc.raw_url} target="_blank">Vorschau-Link</a></p>
                }
                <div className='form-group'>
                    <label htmlFor={doc.raw_url + "_name"}>Name</label>
                    <div className="input-group">
                        <input
                            id={doc.raw_url + "_name"}
                            type="text"
                            className="form-control"
                            value={doc.name}
                            onChange={event => changeDoc({ ...doc, name: event.target.value })}
                        //pattern={name_pattern}
                        />
                    </div>
                    {/* <small
                        className="form-text text-muted">Keine doppelten namen. Buchstaben, Zahlen, Bindestriche, Datei-Endung.
                    </small> */}
                </div>
                <div className='form-group'>
                    <label htmlFor={doc.raw_url + "_description"}>Beschreibung</label>
                    <div className="input-group">
                        <textarea
                            id={doc.raw_url + "_description"}
                            className="form-control"
                            value={doc.description}
                            onChange={event => changeDoc({ ...doc, description: event.target.value })}
                        />
                    </div>
                </div>
                <div className='form-group'>
                    <div className='input-group'>
                        <button
                            onClick={e => removeDoc(doc.raw_url)}
                            className='btn btn-secondary'
                            role='button'>Entfernen</button>
                    </div>
                </div>
            </div>
        </article>
    )

}

function Documents({ field }) {
    const initial_list = field.attributes.defaultValue ? field.attributes.defaultValue : [];
    console.log({ initial_list });
    const [doc_state, setDocState] = useState({
        list: initial_list,
    });

    const json_value = JSON.stringify(doc_state.list);

    async function uploadFile(event) {
        console.log('uploading file', event.target.files[0]);
        const form_data = new FormData();
        form_data.append('document', event.target.files[0]);
        const res = await fetch('/json/upload/document', {
            method: 'POST',
            headers: {
                'X-XSRF-TOKEN': getCookie('XSRF-TOKEN')
            },
            body: form_data
        });
        const data = await res.json();
        console.log('uploaded file', data);
        setDocState({
            ...doc_state,
            list: [...doc_state.list, data],
        });
    }

    function removeDoc(raw_url) {
        setDocState({
            ...doc_state,
            list: doc_state.list.filter(doc => {
                return doc.raw_url !== raw_url;
            }),
        });
    }

    function changeDoc(new_doc) {
        setDocState({
            ...doc_state,
            list: doc_state.list.map(doc => {
                if (doc.raw_url === new_doc.raw_url) {
                    return new_doc;
                }
                return doc;
            }),
        });
    }

    return (
        <div>
            {
                doc_state.list.length <= 0
                    ? <p><em>Keine Dokumente</em></p>
                    : (
                        <div className='form-row'>
                            {
                                doc_state.list.map(doc => {
                                    return (
                                        <div key={doc.raw_url} className='col-sm-6'>
                                            <DocumentItem
                                                doc={doc}
                                                removeDoc={removeDoc}
                                                changeDoc={changeDoc}
                                            // names={
                                            //     doc_state.list.
                                            //         filter(other_doc => other_doc.raw_url !== doc.raw_url)
                                            //         .map(doc => doc.name)
                                            // }
                                            />
                                        </div>
                                    );
                                })
                            }
                        </div>
                    )
            }

            <div className="input-group">
                <label htmlFor={field.name}>{"Hochladen" + (field.attributes.required ? '*' : '')}</label>
                <input type="file" accept='image/*,application/pdf' className="form-control-file" onChange={uploadFile} />
                <input type="hidden" name={field.name} defaultValue={json_value} />
            </div>
        </div>
    );
}

function MonthInput({ field, onChange, temporalHook }) {
    const defaultValue = field.attributes.defaultValue || "";
    // format default value like MM/YY
    const formattedDefaultValue = defaultValue.replace(/(\d{2})(\d{2})-(\d{2})-(\d{2})/, '$3/$2').slice(0, 5);

    const [date, setDate] = useState(formattedDefaultValue);

    function handleChange(e) {
        // remove all non-numeric characters except dots "."
        const value = e.target.value.replace(/[^0-9\/]/g, '');
        // crop all characters after the 5th
        if (value.length > 5) {
            return;
        }
        // extract the month
        const month = value.slice(0, 2);
        // check if the month is valid
        if (parseInt(month) > 12) {
            console.log('Monat ist ungültig');
            e.target.setCustomValidity('Monat ist ungültig');
        } else {
            console.log('Monat ist gültig');
            e.target.setCustomValidity('');
        }

        setDate(value);
        //onChange(e);
    }

    let maskedValue = "";
    if (date.length === 3 && date.indexOf('/') === -1) {
        maskedValue = date.replace(/(\d{2})/, '$1/');
    } else if (date.length > 2) {
        maskedValue = date.replace(/(\d{2})(\d{2})/, '$1/$2');
    } else {
        maskedValue = date;
    }

    // actual value is YYYY-MM
    let actualValue = "";
    if (date.length === 5) {
        actualValue = date.replace(/(\d{2})\/(\d{2})/, '20$2-$1-19');
    }

    return (
        <div className="input-group">
            <input
                type="text"
                className="form-control"
                value={maskedValue}
                onChange={handleChange}
            />
            <input type="hidden" name={field.name} value={actualValue} />
        </div>
    );
}

function InputField({ field, temporal, onChange, disabled, target_date }) {
    //console.log('disabled', disabled);
    disabled = disabled || field.attributes.disabled;
    function temporalHook(e) {
        if (!temporal) {
            return;
        }
        //console.log('temporal', e.target.value);
    }

    if (field.Tag === 'select') {
        field.options.sort((a, b) => {
            if (a.label < b.label) {
                return -1;
            }
            if (a.label > b.label) {
                return 1;
            }
            return 0;
        });

        const grouped_options = groupBy(field.options, opt => opt.value);

        // test if every entity has a valid option
        // if not, add a invalid date option
        //console.log('grouped', grouped_options);
        let options;
        if (field.app_type === 'relational') {
            options = parse_into_relational_options(grouped_options, target_date);
            console.log('options relational', options);
        } else {
            options = field.options;
        }

        const singleTypeaheadSelected = typeof field.attributes.defaultValue !== 'undefined'
            ? (options.filter(opt => {
                return opt.value === field.attributes.defaultValue;
            }).map(opt => {
                return { ...opt };
            }))
            : [];
        // console.log('options', field.options);
        // console.log('selected', singleTypeaheadSelected);
        const [state_selected, changeStateSelected] = useState(singleTypeaheadSelected);
        //const [state_options, changeStateOptions] = useState(field.options);

        const changeSingleTypeAhead = (inputValue) => {
            if (field.app_type === 'relational') {
                // do nothing special
            }
            changeStateSelected(inputValue);
        };
        const selected_uuid = options.reduce((uuid, opt) => {
            // console.log('check selected uuid', opt.label, state_selected)
            if (state_selected.length === 1 && opt.label === state_selected[0].label) {
                return opt.value;
            }
            return uuid;
        }, "");
        // console.log('selected_uuid', selected_uuid);
        if (selected_uuid.length > 0) {
            const new_input_selected = options.reduce((new_input_selected, opt) => {
                if (opt.value === selected_uuid) {
                    return [opt];
                }
                return new_input_selected;
            }, []);
            // changeStateSelected(new_input_selected);
            // return;
            var modified_selected = new_input_selected
        } else {
            var modified_selected = state_selected;
        }
        let input_props = {
            name: field.name,
            pattern: '(' + options.map(opt => opt.label.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')).join('|') + ')',
            'id': field.key_hash
        };
        input_props.required = !!field.attributes.required;
        return (
            <Typeahead
                id={field.name}
                //labelKey="name"
                inputProps={input_props}
                onChange={changeSingleTypeAhead}
                options={options}
                placeholder="Auswählen..."
                // defaultSelected={singleTypeaheadSelected}
                // defaultSelected={state_selected}
                selected={modified_selected}
                disabled={disabled}

            />
        );
    }
    if (field.Tag === 'select') {
        return (
            <field.Tag
                name={field.name}
                className="form-control"
                {
                ...(field.help && {
                    "aria-describedby": field.name + "_help"
                })
                }
                {...field.attributes}
            >
                <option key="empty" value="">leer</option>
                {
                    field.options.sort((a, b) => {
                        if (a.label < b.label) {
                            return -1;
                        }
                        if (a.label > b.label) {
                            return 1;
                        }
                        return 0;
                    }).map(opt => {
                        return <option key={opt.value} value={opt.value}>{opt.label}</option>
                    })
                }
            </field.Tag>
        );
    }
    if (field.attributes.type === 'file') {
        return (<FileInput field={field} onChange={onChange} temporalHook={temporalHook} />)
    }
    if (field.attributes.type === 'month') {
        return (<MonthInput field={field} onChange={onChange} temporalHook={temporalHook} />)
    }
    return (
        <field.Tag
            name={field.name}
            className="form-control"
            onChange={onChange}
            onBlur={temporalHook}
            {
            ...(field.help && {
                "aria-describedby": field.name + "_help"
            })
            }
            {...field.attributes}
        />
    );
}

function DynamicField({ field, temporal, onChange, disabled, target_date, resource, removeMulti }) {
    //console.log(field);
    const sub_fields = field.sub_fields || [field];
    // TODO: too specific
    if (field.sub_fields && typeof field.attributes.defaultValue !== 'undefined' && field.attributes.defaultValue !== null) {
        sub_fields.forEach((sub_field, sub_key) => {
            if (sub_field.name === 'dc_system_date_of_installation_year') {
                sub_fields[sub_key].attributes.defaultValue = new Date(field.attributes.defaultValue).getFullYear();
            } else if (sub_field.name === 'dc_system_date_of_installation_month') {
                sub_fields[sub_key].attributes.defaultValue = new Date(field.attributes.defaultValue).getMonth() + 1;
            }
        });
    }
    console.log('subfields', field, sub_fields);
    return (
        <>
            {
                sub_fields.map((field) => (
                    <div key={"field_" + field.name} className={`form-group col-sm-${['relational_multi', 'relational'].includes(field.app_type) ? '12' : '6'}` + (field.name === 'number' && resource === 'client' ? " mr-1" : "")}>
                        <label htmlFor={field.name}>{field.label + (field.attributes.required ? '*' : '')}</label>
                        <div className="input-group">
                            {
                                field.prepend &&
                                <div className="input-group-prepend">
                                    <span className="input-group-text">{field.prepend}</span>
                                </div>
                            }
                            <InputField field={field} temporal={temporal} onChange={onChange} disabled={disabled} target_date={target_date} />
                            {
                                field.append &&
                                <div className="input-group-append">
                                    <span className="input-group-text">{field.append}</span>
                                </div>
                            }
                            {
                                field.app_type === 'relational_multi' &&
                                <div className="input-group-append">
                                    <button className="btn btn-outline-secondary" type="button" onClick={e => removeMulti(field.name)}>Entfernen</button>
                                </div>
                            }
                        </div>
                        {
                            field.help &&
                            <small
                                id={field.name + "_help"}
                                className="form-text text-muted">{field.help}
                            </small>
                        }
                    </div>
                ))
            }
        </>
    )
}

export default function Form({ resource, fields, rules, id, message_dispatch, prefill }) {
    const [sfields, setFields] = useState(fields.map(field => ({ ...field, key_hash: uniqueId() })));
    console.log('sfields', sfields);
    const grouped_fields = groupBy(sfields, field => field.app_type);
    const std_valid_from = ['report', 'user'].includes(resource) ? null : grouped_fields.temporal.filter(field => field.name === 'valid_from')[0].attributes.defaultValue;
    const [picker_valid_from, setPickerValidFrom] = useState(std_valid_from);
    const std_valid_to = ['report', 'user'].includes(resource) ? null : grouped_fields.temporal.filter(field => field.name === 'valid_to')[0].attributes.defaultValue;
    const [picker_valid_to, setPickerValidTo] = useState(std_valid_to);
    const now = new Date();
    const today = `${now.getFullYear()}-${(now.getMonth() + 1).toString().padStart(2, '0')}-${now.getDate().toString().padStart(2, '0')}`;
    let def_target_date = today;
    if (resource === 'report') {
        def_target_date = grouped_fields.temporal.filter(field => field.name === 'date')[0].attributes.defaultValue || def_target_date;
    }
    const [target_date, setTargetDate] = useState(def_target_date);
    function changeTargetDate(e) {
        console.log('changeTargetDate', e.target.value);
        setTargetDate(e.target.value);
    }
    //console.log(sfields);
    let req_object;
    if (id && !prefill) {
        req_object = {
            url: `/json/${resource}/${id}`,
            method: 'post'
        };
    } else {
        req_object = {
            url: `/json/${resource}`,
            method: 'post'
        };
    }
    const [{ data, loading, error }, sendData] = useAxios(req_object, { manual: true });
    if (loading) {
        // TODO
        // console.log('axios loading', loading);
    } else if (error) {
        // TODO
        // console.log('axios error', error);
    } else {
        // TODO
        // console.log('axios else', data);
        if (data) {
            console.log(data);
            if (data.payload.redirect) {
                return <Redirect to={data.payload.redirect} />;
            }
            if (data.payload.entity_uuid) {
                return <Redirect to={`/resource/${resource}/${data.payload.entity_uuid}?${(new URLSearchParams({ "valid_date": data.payload.valid_date }).toString())}`} />
            }
        }
    }
    const validateAndSend = async targetForm => {
        const field_values = sfields.reduce((data, field) => {
            let value = null;
            if (field.name === 'pdf') {
                console.log('pdf value', targetForm[field.name].value);
                console.log('pdf files', targetForm[field.name].files);
                if (targetForm[field.name].files === null) {
                    value = targetForm[field.name].value.length === 0 ? null : targetForm[field.name].value;
                } else if (targetForm[field.name].files.length > 0) {
                    value = targetForm[field.name].files[0];
                }
            } else if (field.Tag === 'select') {
                // const parsed_options = field.app_type === 'relational'
                //     ? parse_into_relational_options(groupBy(field.options, opt => opt.value))
                //     : field.options;
                const parsed_options = field.options;
                console.log('parsed options', parsed_options);
                // [...targetForm].forEach(input => {
                //     //console.log('form field', input.name, input.value);
                // });

                let target_form_field = targetForm[field.name];
                if (targetForm[field.name] instanceof NodeList) {
                    targetForm[field.name].forEach(field_item => {
                        console.log('comparing', field_item.id, field.key_hash);
                        if (field_item.id === field.key_hash) {
                            target_form_field = field_item;
                        }
                    });
                }

                console.log(target_form_field);

                if (target_form_field.value.length === 0) {
                    value = null;
                } else {
                    const value_raw = target_form_field.value;
                    let filtered = parsed_options.filter(opt => {
                        return opt.label === value_raw;
                    });
                    if (filtered.length === 0) {
                        value = parsed_options.filter(opt => {
                            return opt.meta.fallback_label === value_raw;
                        })[0].value;
                    } else {
                        value = filtered[0].value;
                    }
                }
            } else if (field.sub_fields) {
                if (targetForm['dc_system_date_of_installation_year'].value !== null
                    && targetForm['dc_system_date_of_installation_month'].value !== null
                    && targetForm['dc_system_date_of_installation_year'].value.length > 0
                    && targetForm['dc_system_date_of_installation_month'].value.length > 0) {
                    // TODO: too specific
                    const dc_date = new Date('August 19, 1975 24:00:01');
                    dc_date.setFullYear(parseInt(targetForm['dc_system_date_of_installation_year'].value));
                    dc_date.setMonth(targetForm['dc_system_date_of_installation_month'].value - 1);
                    value = dc_date.toISOString().slice(0, 19).replace('T', ' ');
                }
            } else {
                value = targetForm[field.name].value.length === 0 ? null : targetForm[field.name].value;
            }
            //data[field.name] = value;
            const field_name = field.app_type === 'relational_multi' ? field.name + '[]' : field.name;

            if (value !== null) {
                data.append(field_name, value);
            } else {
                data.append(field_name, value);
            }
            return data;
        }, new FormData());
        try {
            for (let fd of field_values) {
                console.log('field value', fd);
            }
            //console.log('fieldvalues',JSON.stringify(field_values.entries().map(entry => entry)));
            const result = await sendData({ data: field_values });
            //message_dispatch({ type: 'push', payload: { status: 'ok', msg: result.data.msg } });
        } catch (e) {
            message_dispatch({ type: 'push', payload: { status: 'error', msg: e } });
        }
    };

    function addMulti(field) {
        console.log('addfield', field);
        setFields([...sfields, {
            ...field,
            key_hash: uniqueId(),
            attributes: {
                ...(field.attributes),
                defaultValue: null,
                //ref: focusRef,
            }
        }]);
    }

    function removeMulti(name, key_hash) {
        let real_idx = -1;
        sfields.forEach((field, i) => {
            if (field.name === name && field.key_hash === key_hash) {
                real_idx = i;
            }
        })
        sfields.splice(real_idx, 1);
        setFields([...sfields]);
    }

    return (
        <form
            onSubmit={e => {
                e.preventDefault();
                validateAndSend(e.target);
            }}
            onChange={e => {
                if (rules) {
                    setFields(rules(sfields, e.target));
                }
            }}
            onBlur={e => {
                if (rules) {
                    setFields(rules(sfields, e.target));
                }
            }}
        //encType="multipart/form-data"
        >
            {
                grouped_fields.temporal && (
                    <div className="temporal-fields">
                        <h4>Temporal</h4>
                        {
                            resource === 'report' && (
                                <div className="form-row">
                                    {
                                        grouped_fields.temporal.map(field => (
                                            <DynamicField field={field} key={field.name} temporal={true} onChange={changeTargetDate} />
                                        ))
                                    }
                                </div>
                            )
                        }
                        {
                            resource !== 'report' && (
                                <details>
                                    <summary>
                                        {getValidTime(picker_valid_from, 'valid_from')} bis {getValidTime(picker_valid_to, 'valid_to')}
                                    </summary>
                                    <div className="form-row">
                                        {
                                            grouped_fields.temporal.map(field => {
                                                let onChange = null;
                                                if (field.name === 'valid_from') {
                                                    onChange = e => {
                                                        setPickerValidFrom(e.target.value);
                                                    };
                                                } else if (field.name === 'valid_to') {
                                                    onChange = e => {
                                                        setPickerValidTo(e.target.value);
                                                    };
                                                }
                                                return (
                                                    <DynamicField field={field} key={field.name} temporal={true} onChange={onChange} />
                                                );
                                            })
                                        }
                                    </div>
                                </details>
                            )
                        }
                        <hr />
                    </div>
                )
            }
            {
                grouped_fields.relational && (
                    <>
                        <h4 className="">Relationen</h4>
                        {
                            resource !== 'report' && (
                                <div className="d-flex">
                                    <div className="form-group">
                                        <label htmlFor="valid-date">Stichtag</label>
                                        <div className="input-group">
                                            <input
                                                name="target-date"
                                                type="date"
                                                className="form-control date-picker-width"
                                                value={target_date}
                                                onChange={changeTargetDate}
                                            />
                                            {/* <div className="input-group-append">
                                                <button className="btn btn-secondary" onClick={chooseTargetDate}>Filtern</button>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                        <div className="form-row">
                            {
                                grouped_fields.relational.map(field => (
                                    <DynamicField field={field} key={field.name} target_date={target_date} disabled={resource === 'report' && field.name === 'instrument_uuid'} />
                                ))
                            }
                        </div>
                        <hr />
                    </>
                )
            }
            <h4 className="">Attribute</h4>
            <div className="form-row">
                {
                    grouped_fields.attribute.map(field => (
                        <DynamicField field={field} key={field.name} resource={resource} />
                    ))
                }
            </div>
            {
                grouped_fields.checklist && (
                    <>
                        <h4 className="">Checkliste</h4>
                        <div className="form-row">
                            {
                                grouped_fields.checklist.map(field => (
                                    <DynamicField field={field} key={field.name} />
                                ))
                            }
                        </div>
                        <hr />
                    </>
                )
            }
            {
                grouped_fields.relational_multi && (
                    <>
                        {/* <h4 className="">Kunden</h4> */}
                        <div className="form-row">
                            {
                                grouped_fields.relational_multi.map((field) => {
                                    const multi_field = { ...field, name: field.name };
                                    return (
                                        <React.Fragment key={multi_field.name + "_" + field.key_hash}>
                                            <DynamicField field={multi_field} removeMulti={() => removeMulti(field.name, field.key_hash)} />
                                            <button className="ml-1 mb-2 btn btn-secondary add-multi" type="button" onClick={e => addMulti(field)}>{multi_field.label} hinzufügen</button>
                                        </React.Fragment>
                                    )
                                })
                            }
                        </div>
                        <hr />
                    </>
                )
            }
            {
                grouped_fields.documents && (
                    <>
                        <h4 className="">Dokumente</h4>
                        <div className="">
                            {
                                grouped_fields.documents.map(field => (
                                    <Documents field={field} key={field.name} />
                                ))
                            }
                        </div>
                        <hr />
                    </>
                )
            }
            {
                id && !prefill
                    ? <button className="btn btn-primary">Speichern</button>
                    : <button className="btn btn-success">Erstellen</button>
            }
        </form>
    )
}