const rules = {
    person: (fields, field) => {
        return fields.map(old_field => {
            if (field.name === 'valid_from' && old_field.name === 'valid_to') {
                old_field.attributes.min = field.value;
            }
            if (field.name === 'role' && old_field.name === 'email') {
                console.log('role', field);
                old_field.attributes.required = field.value !== 'none' && field.value !== 'Keine';
            }
            return old_field;
        });
    }
};

export default function useFormRules(resource) {
    return rules[resource];
}