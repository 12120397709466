import React, { useState } from 'react';
import { Redirect, useLocation, useParams, withRouter } from 'react-router-dom';
import useAxios from 'axios-hooks';
import Form from '../components/Form.js';
import Loading from '../components/Loading.js';
import useFormRules from '../lib/useFormRules.js';
import { getLabel, menschifyDateSwiss } from '../lib/labels.js';

function ResourceForm({ message_dispatch, history, user_dispatch }) {
	const { resource, id, db_id } = useParams();
	const query = new URLSearchParams(useLocation().search);
	const instrument = query.get('instrument');
	const prefill = !!query.get('prefill');
	const form_url = id && db_id ? `/json/${resource}/form/${id}/${db_id}` : `/json/${resource}/form`;
	const [{ data, loading, error }, refetch] = useAxios(form_url, { useCache: false });

	if (loading) {
		return (
			<Loading />
		);
	}

	if (error) {
		if (error.response.status === 401) {
			user_dispatch({type: 'logged-out'});
            return null;
        }
		return <React.Fragment><p>Error</p><pre>{JSON.stringify(error)}</pre></React.Fragment>
	}

	let { fields } = data.payload;
	//console.log('fields pre', JSON.stringify(fields, null, 4));
	if (resource === 'report' && instrument && !prefill) {
		fields = fields.map(field => {
			if (field.name === 'instrument_uuid') {
				field.attributes.defaultValue = instrument;
			}
			return field;
		})
	}
	if (resource === 'report' && prefill) {
		fields = fields.map(field => {
			if (field.name === 'date') {
				const now = new Date();
				field.attributes.defaultValue = `${now.getFullYear()}-${(now.getMonth() + 1).toString().padStart(2, '0')}-${now.getDate().toString().padStart(2, '0')}`;
			}
			return field;
		});
	}
	const field_with_labels = fields.map(field => {
		if (field.sub_fields) {
			field.sub_fields = field.sub_fields.map(field => ({ ...field, label: getLabel(field.name, resource) }));
		}
		if (field.name === 'report_uuid' && resource === 'offer') {
			console.log('report field', field);
			field.options = field.options.map(opt => {
				const swiss_label = opt.label.slice(0, -20) + menschifyDateSwiss(opt.label.slice(-20, -10)) + opt.label.slice(-10);
				return {...opt, label: swiss_label};
			})
		}
		return { ...field, label: getLabel(field.name, resource) };
	});
	const rules = useFormRules(resource);

	return (
		<section>
			<header className="bg-grayl py-3 d-flex mb-4">
				<div className="container d-flex">
					<section className="flex-grow-1 w-50">
						<h1 className="subtitle">{getLabel(resource) + (id ? ' Bearbeiten' : ' Erstellen')}</h1>
					</section>
					<section className="flex-grow-1 w-50 header-spacer">
					</section>
				</div>
			</header>
			<main className={"container mb-4 resource-form " + resource}>
				<Form
					prefill={prefill}
					resource={resource}
					fields={field_with_labels}
					rules={rules}
					id={id}
					message_dispatch={message_dispatch}
				/>
			</main>
		</section>
	);
}

export default withRouter(ResourceForm);