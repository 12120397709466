import React from 'react';
import { useParams, Link, useLocation, withRouter, Redirect } from 'react-router-dom';
import useAxios from 'axios-hooks';
import { getLabel, getName, getHistoryFields, isRelationUUID, isRelationName, getRelName, getRelationsInFilterable, getLabelPlural, getLabelPluralAcc, menschifyDate, menschifyDateShort, role_resources, isRelationAdd, menschifyDateSwiss } from '../lib/labels.js';
import _, { map } from 'lodash';
import Loading from '../components/Loading.js';
import CHFString from '../components/CHFString.js';

function youngestValidTo(entries) {
    const valid_to = entries.reduce((youngest, entry) => {
        if (entry.valid_to > youngest) {
            return entry.valid_to;
        }
        return youngest;
    }, entries[0].valid_to);
    return valid_to;
}

function getDisplay(key, field) {
    //console.log(key);
    if (key === "dc_system_date_of_installation") {
        return menschifyDateShort(field);
    }
    if (key === 'email') {
        return <a href={"mailto:" + field}>{field}</a>;
    }
    if (key === 'pdf') {
        return <a href={field.url} target="_blank">herunterladen</a>;
    }
    if (key === 'date') {
        return menschifyDateSwiss(field);
    }
    if (key === 'amount') {
        return <CHFString value={field} />
    }
    if (key === 'clients') {
        return (
            <span>
                {
                    field.map(client => {
                        return <div key={client.client_uuid}><Link to={`/resource/client/${client.client_uuid}`}>{client.client_rel_name}</Link></div>
                    })
                }
            </span>
        );
    }
    if (key === 'persons') {
        return (
            <span>
                {
                    field.map(person => {
                        return <div key={person.person_uuid}><Link to={`/resource/person/${person.person_uuid}`}>{person.person_rel_name}</Link></div>
                    })
                }
            </span>
        );
    }
    if (key === 'documents') {
        const documents = [...field];
        documents.sort((a, b) => {
            if (a.name < b.name) {
                return -1;
            };
            if (a.name > b.name) {
                return 1;
            };
            return 0;
        });
        return (
            <article>
                {
                    documents.length <= 0 ? '-' : documents.map((doc, idx) => {
                        if (/\.(png|jpg|jpeg)$/.test(doc.url.toLowerCase())) {
                            return (
                                <div className={idx === documents.length - 1 ? '' : 'border-bottom mb-2'} key={doc.raw_url}>
                                    <p className='mb-1'><a href={doc.url} target='_blank'>
                                        {doc.name}<br />
                                        <img className='w-100' src={doc.url} />
                                    </a></p>
                                    <p className='mb-1'>{doc.description}</p>
                                </div>
                            );
                        }
                        return (
                            <div className={idx === documents.length - 1 ? '' : 'border-bottom mb-2'} key={doc.raw_url}>
                                <p className='mb-1'><a href={doc.url} target='_blank'>{doc.name}</a></p>
                                <p className='mb-1'>{doc.description}</p>
                            </div>
                        );
                    })
                }
            </article>
        );
    }
    let label = getLabel(field);
    if (label) {
        return label;
    }
    return field;
}

function getRelated(resource, uuid, name, is_super, valid_date, role) {
    // achtung bombe
    if (is_super || role_resources[role].includes(resource) || resource === 'report') {
        if (resource === 'report') {
            name = name.slice(0, -10) + menschifyDateSwiss(name.slice(-10));
            return <a href={"/resource/" + resource + "/" + uuid + `?valid_date=${valid_date}`}>{name}</a>;
        }
        return <a href={"/resource/" + resource + "/" + uuid + `?valid_date=${valid_date}`}>{name}</a>;
    }
    return <span>{name}</span>;
}

function addField(field) {
    return (
        <React.Fragment>
            <dt className="col-sm-3"></dt>
            <dd className="col-sm-9">{field}</dd>
        </React.Fragment>
    );
}

const person_field_add_map = {
    'person_reladd_email': 'email',
    'person_reladd_phone': 'phone',
    'person_reladd_mobile': 'mobile',
    'person_reladd_notes_internal': 'notes_internal',
};

function getRelAdd(field_name) {
    return person_field_add_map[field_name];
}

function ReportCell({ val, valid_date }) {
    const date_pieces = val.name.split('-');
    const date_string = `${date_pieces[2]}.${date_pieces[1]}.${date_pieces[0]}`;
    return <Link to={'/resource/report/' + val.uuid + `?valid_date=${valid_date}`}>Zum Report: {date_string}</Link>
}

function Entries({ resource, data_entries, data_fields }) {
    // console.log('entries', data_entries);
    // console.log('entries', data_fields);
    return (
        <>
            <p>Sämtliche Einträge dieser Ressource:</p>
            <ul className="mono-font">
                {
                    data_entries.map(entry => {
                        return (
                            <li key={entry.id}>
                                {
                                    data_fields === null || data_fields.id !== entry.id
                                        ? (
                                            <Link to={`/resource/${resource}/${data_fields.entity_uuid}?valid_date=${entry.valid_from}`}>
                                                {menschifyDateSwiss(entry.valid_from)} - {menschifyDateSwiss(entry.valid_to)}
                                            </Link>
                                        )
                                        : <strong>{menschifyDateSwiss(entry.valid_from)} - {menschifyDateSwiss(entry.valid_to)} (Dieser Eintrag)</strong>
                                }
                            </li>
                        );
                    })
                }
            </ul>
        </>
    )
}

function TemporalInfo({ data_fields }) {
    return (
        <dl className="row mb-0">
            <dt className="col-md-3">Eingetragen:</dt>
            <dd className="col-md-9 mono-font">{menschifyDate(data_fields.tx_from)}</dd>
            <dt className="col-md-3">Gültig von:</dt>
            <dd className="col-md-9 mono-font">{menschifyDateSwiss(data_fields.valid_from)}</dd>
            <dt className="col-md-3">Gültig bis:</dt>
            <dd className="col-md-9 mono-font">{data_fields.valid_to === null ? "∞" : menschifyDateSwiss(data_fields.valid_to)}</dd>
        </dl>
    );
}

function FormLink({ resource, data_fields }) {
    const form_uri = resource === 'user' ? `${data_fields.id}` : `${data_fields.entity_uuid}/${data_fields.id}`;
    const form_url = `/resource/${resource}/form/${form_uri}`;
    return (
        <Link
            className="btn btn-primary mr-2 mb-2"
            to={form_url}>
            Neu Hinterlegen
        </Link>
    )
}

function DeleteLink({ resource, data_fields }) {
    const delete_uri = resource === 'user' ? `${data_fields.id}` : `${data_fields.entity_uuid}/${data_fields.id}`;
    const delete_url = `/resource/${resource}/delete/${delete_uri}`;
    return (
        <Link
            className="btn btn-danger mr-2 mb-2"
            to={delete_url}>
            Löschen
        </Link>
    );
}

function EntityDataList({ resource, valid_date, data_fields, is_super, role }) {
    if (!data_fields) {
        return (
            <section className="flex-grow-1 w-50">
                <p>Es wurde kein Eintrag für dieses Gültigkeits-Datum gefunden.</p>
            </section>
        );
    }
    const historyFields = getHistoryFields();
    const skipFields = ['finish'];
    const data_entries = Object.entries(data_fields).filter(entry => {
        return !historyFields.includes(entry[0]) && !skipFields.includes(entry[0]);
    });
    const [data_entries_owned, data_entries_relations] = _.partition(data_entries, entry => {
        return !isRelationUUID(entry[0]) && !isRelationName(entry[0]) && !isRelationAdd(entry[0]);
    });
    const data_entries_relations_grouped = Object.entries(data_entries_relations.reduce((grpd, entry) => {
        const rel_name = getRelName(entry[0]);
        // hack
        if (rel_name === 'report' && resource === 'instrument') {
            return grpd;
        }
        if (!grpd.hasOwnProperty(rel_name)) {
            grpd[rel_name] = {};
        }
        if (isRelationUUID(entry[0])) {
            grpd[rel_name].uuid = entry[1];
        } else if (isRelationName(entry[0])) {
            grpd[rel_name].name = entry[1];
        } else if (isRelationAdd(entry[0])) {
            let rel_add = getRelAdd(entry[0]);
            grpd[rel_name][rel_add] = entry[1];
        }
        return grpd;
    }, {}));

    // ugly
    let data_entries_owned_sorted = data_entries_owned;
    if (resource === 'client') {
        const temp = data_entries_owned_sorted[6];
        data_entries_owned_sorted[6] = data_entries_owned_sorted[5];
        data_entries_owned_sorted[5] = temp;
    }
    return (
        <section className="flex-grow-1 w-50">
            <dl className="row">
                {/* {
                    data_entries_relations.length <= 0 && <p className="col-sm-12">keine Relationen</p>
                } */}
                {
                    data_entries_relations_grouped.map(field => (
                        <React.Fragment key={field[0]}>
                            <dt className="col-sm-3">{getLabel(field[0], resource)}</dt>
                            <dd className="col-sm-9">
                                {
                                    field[1] && field[1].name
                                        ? getRelated(field[0], field[1].uuid, field[1].name, is_super, valid_date, role)
                                        : "-"
                                }</dd>
                            {
                                field[1].email && addField((<a href={"mailto:" + field[1].email}>{field[1].email}</a>))
                            }
                            {
                                field[1].phone && addField(field[1].phone)
                            }
                            {
                                field[1].mobile && addField(field[1].mobile)
                            }
                            {
                                field[1].notes_internal && addField(field[1].notes_internal)
                            }
                        </React.Fragment>
                    ))
                }
            </dl>
            <dl className="row">
                {
                    data_entries_owned_sorted.map(field => {
                        if (['notes_internal', 'documents'].includes(field[0]) && role === 'client') {
                            return null;
                        }
                        if (field[0] === 'employed' && resource === 'person') {
                            return null;
                        }
                        return (
                            <React.Fragment key={field[0]}>
                                <dt className="col-sm-3">{getLabel(field[0], resource)}</dt>
                                <dd className="col-sm-9">{field[1] ? getDisplay(field[0], field[1]) : "-"}</dd>
                            </React.Fragment>
                        )
                    })
                }
            </dl>
        </section>
    );
}

function not_is_super(resource, data_fields) {
    // console.log('not is super', resource, data_fields);
    return !(resource === 'user' && data_fields.role === 'super');
}

function ResourceDetail({ history, is_super, role, user_dispatch }) {
    const { resource, id } = useParams();
    const query = new URLSearchParams(useLocation().search);
    const now = new Date();
    const valid_date = query.get('valid_date') || `${now.getFullYear()}-${(now.getMonth() + 1).toString().padStart(2, '0')}-${now.getDate().toString().padStart(2, '0')}`;
    const [{ data, loading, error }, refetch] = useAxios(`/json/${resource}/${id}?${(new URLSearchParams({ "valid_date": valid_date }).toString())}`, { useCache: false });

    if (loading) {
        return (
            <Loading />
        );
    }

    if (error) {
        if (error.response.status === 401) {
            user_dispatch({ type: 'logged-out' });
            return null;
        }
        console.log(error.response.data);
        // todo: error handling
        return (
            <section>
                <header className='bg-grayl py-3 d-flex mb-2'>
                    <div className='container d-flex'>
                        <section className='flex-grow-1 w-50'>
                            <h1 className='subtitle d-inline-block pt-2'>Error {error.response.status}</h1>
                        </section>
                    </div>
                </header>
                <main>
                    <div className='container'>
                        {error.response.status === 404 || error.response.status === 422 ? <h2>Die Ressource wurde nicht gefunden.</h2> : <h2>Es ist ein Fehler aufgetreten.</h2>}
                        {['super', 'admin'].includes(role) && (error.response.status === 404 || error.response.status === 422) && error.response.data?.payload?.entries && error.response.data.payload.entries.length > 0 && (
                            <>
                                <p>Sie wurde archiviert am <a href={'?valid_date=' + youngestValidTo(error.response.data.payload.entries)}>{menschifyDateSwiss(youngestValidTo(error.response.data.payload.entries))}</a></p>
                                {
                                    ['super', 'admin'].includes(role) && (
                                        <details>
                                            <summary>
                                                Weitere Archiv-Einträge:
                                            </summary>
                                            <pre className='d-block'>{JSON.stringify(error.response.data.payload.entries, null, '\t')}</pre>
                                        </details>
                                    )
                                }
                            </>
                        )}
                        {['super', 'admin'].includes(role) && (
                            <details>
                                <summary>
                                    Fehlermeldung Detail
                                </summary>
                                <pre className='d-block'>{JSON.stringify(error, null, '\t')}</pre>
                            </details>
                        )}
                    </div>
                </main>
            </section>
        );
    }

    const data_fields = resource === 'user' ? data.payload : data.payload.valid;
    const data_entries = data.payload.entries;
    // const relations_in_filterable = getRelationsInFilterable();

    // const relations_in = Object.entries(relations_in_filterable).reduce((rel_in, entry) => {
    //     if (entry[1].includes(resource)) {
    //         rel_in.push(entry[0]);
    //     }
    //     return rel_in;
    // }, []);

    const relations_in = data.payload.count_relations_in;

    function changeValidDate(e) {
        e.preventDefault();
        let chosen_date = e.target['valid-date'].value;
        if (chosen_date.length < 1) {
            //return;
            const now = new Date();
            chosen_date = `${now.getFullYear()}-${(now.getMonth() + 1).toString().padStart(2, '0')}-${now.getDate().toString().padStart(2, '0')}`
        }
        const redirect_description = {
            pathname: `/resource/${resource}/${id}`,
            search: "?" + new URLSearchParams({ "valid_date": chosen_date }).toString()
        };
        history.push(redirect_description);
    }

    return (
        <section>
            <header className="bg-grayl container-fluid py-3 d-flex mb-4">
                <div className="container d-md-flex">
                    <section className="flex-grow-1">
                        <h1 className="subtitle">{getLabel(resource)}</h1>
                        {
                            data_fields !== null && (
                                <>
                                    {
                                        data_fields.tx_from && role !== 'client' && <TemporalInfo data_fields={data_fields} />
                                    }
                                    {
                                        (['super', 'admin'].includes(role) && (role === 'super' || not_is_super(resource, data_fields))) && (
                                            <div>
                                                <FormLink resource={resource} data_fields={data_fields} />
                                                <DeleteLink resource={resource} data_fields={data_fields} />
                                                {
                                                    data_fields.valid_to === null && (
                                                        <Link className="btn btn-warning mb-2" to={`/resource/${resource}/remove/${data_fields.entity_uuid}/${data_fields.id}`}>
                                                            Archivieren
                                                        </Link>
                                                    )
                                                }
                                            </div>
                                        )
                                    }
                                </>
                            )
                        }
                    </section>
                    <section className="flex-grow-1 header-spacer">
                        {
                            ['super', 'admin'].includes(role) && data_fields.valid_from && (
                                <form onSubmit={changeValidDate} className="d-flex justify-content-md-end">
                                    <div className="form-group">
                                        <label htmlFor="valid-date">Gültigkeits-Datum</label>
                                        <div className="input-group">
                                            <input
                                                name="valid-date"
                                                type="date"
                                                className="form-control date-picker-width"
                                                defaultValue={valid_date}
                                            />
                                            <div className="input-group-append">
                                                <button className="btn btn-secondary" type="submit">Filtern</button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            )
                        }
                    </section>
                </div>
            </header>
            <main className="container d-flex">
                <EntityDataList
                    resource={resource}
                    valid_date={valid_date}
                    data_fields={data_fields}
                    is_super={is_super}
                    role={role}
                />
                <section className="flex-grow-1 w-50">
                    {relations_in && relations_in.length > 0 && <p>Zu den:</p>}
                    <ul>
                        {
                            relations_in && Object.entries(relations_in)
                                .filter(([rel, value]) => value.count_in > 0)
                                .map(([rel, value]) => <li key={rel}><Link to={`/resource/${rel}/?${resource}=${data_fields.entity_uuid}&valid_date=${valid_date}`}>{getLabelPluralAcc(rel)}</Link></li>)
                        }
                    </ul>
                    {
                        resource === 'instrument' && data_fields !== null && (
                            data_fields.report_uuid
                                ? <ReportCell val={{ name: data_fields.report_rel_name, uuid: data_fields.report_uuid }} valid_date={valid_date} />
                                : (
                                    ['super', 'admin'].includes(role)
                                        ? <Link to={'/resource/report/form?instrument=' + data_fields.entity_uuid}>Report Erstellen</Link>
                                        : <span>Kein Report</span>
                                )
                        )
                    }
                    {
                        data_entries && role !== 'client' && <Entries resource={resource} data_entries={data_entries} data_fields={data_fields} />
                    }
                </section>
            </main>
        </section>
    );
};

export default withRouter(ResourceDetail);