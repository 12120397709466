import useAxios from 'axios-hooks';
import React from 'react';
import { useParams, withRouter, Redirect } from "react-router-dom";
import Form from '../components/Form';
import Loading from '../components/Loading';
import { getLabel } from '../lib/labels';

function UserForm({ message_dispatch, user_dispatch }) {
    const { db_id } = useParams();
    console.log('user id', db_id)
    const form_url = db_id ? `/json/user/form/${db_id}` : `/json/user/form`;
    const [{ data, loading, error }, refetch] = useAxios(form_url, { useCache: false });
    
    if (loading) {
        return <Loading />
    }

    if (error) {
		if (error.response.status === 401) {
			user_dispatch({type: 'logged-out'});
            return null;
        }
		return <React.Fragment><p>Error</p><pre>{JSON.stringify(error)}</pre></React.Fragment>
	}

    const { fields } = data.payload;
    const field_with_labels = fields.map(field => {
		if (field.sub_fields) {
			field.sub_fields = field.sub_fields.map(field => ({ ...field, label: getLabel(field.name, 'user') }));
		}
		return { ...field, label: getLabel(field.name, 'user') };
	});

    return (
        <section>
			<header className="bg-grayl py-3 d-flex mb-4">
				<div className="container d-flex">
					<section className="flex-grow-1 w-50">
						<h1 className="subtitle">{getLabel("user") + (db_id ? ' Bearbeiten' : ' Erstellen')}</h1>
					</section>
					<section className="flex-grow-1 w-50 header-spacer">
					</section>
				</div>
			</header>
			<main className="container mb-4">
				<Form
					resource={"user"}
					fields={field_with_labels}
					//rules={rules}
					id={db_id}
					message_dispatch={message_dispatch}
				/>
			</main>
		</section>
    );
}

export default withRouter(UserForm)