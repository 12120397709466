import useAxios from 'axios-hooks';
import React from 'react';
import { Redirect } from 'react-router-dom';

export default function Home() {
	const [{ data: user_data, loading: user_loading, error: user_error }, user_refetch] = useAxios('/json/checkauth', { useCache: false });
	if (user_data) {
		if (user_data.user === null) {
			return (<Redirect to='/login' />);
		}
		if (user_data.user.role === 'client') {
			return (<Redirect to='/resource/instrument' />);
		}
		return (<Redirect to='/resource/client' />);
	}
	return null;
}