import useAxios from 'axios-hooks';
import React, { useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import Loading from '../components/Loading';

export default function Logout({user, user_dispatch}) {
    const [{ data: user_data, loading: user_loading, error: user_error }, user_refetch] = useAxios('/json/logout', { useCache: false });

    useEffect(() => {
        if (user_data) {
            user_dispatch({type: 'logged-out'});
        }
    }, [user_data]);

    if (user_loading) {
        return <Loading />
    }

    if (user_error) {
        return null;
    }

	return (
        <Redirect to='/login' />
    );
}