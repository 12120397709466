import React, { useState } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import CHFString from './CHFString.js';
import { getName, getLabel, getLabelPlural, getRelationsInFilterable, isMono, isTextRight, role_resources, menschifyDateSwiss, getTuningPeriod } from '../lib/labels.js';
import { isString, isNumber, isNull, uniqueId } from 'lodash';
import { isNumeric } from 'jquery';

const operationStyles = {
	'deleted': 'table-danger',
	'created': 'table-success',
	'updated': 'table-primary'
};

function DetailsLink({ resource, row, valid_date}) {
	const params = (new URLSearchParams({
		"valid_date": (resource === 'offer' ? row.report.name : valid_date)
	}).toString());
	const identifier = resource === 'user' ? row.id : row.entity_uuid;
	const details_url = `/resource/${resource}/${identifier}?${params}`;
	return (
		<Link
			className="btn btn-secondary btn-sm"
			to={details_url}>
			Details
		</Link>
	)
}

function getOperationStyle(operation) {
	return operationStyles[operation];
}

function stripName(key, name) {
	if (key === 'location') {
		return name.split(', ')[0];
	}
	return name;
}

function ReportStatusCell({ val }) {
	const status_colors = {
		"ok": "bg-success",
		"plannable": "bg-warning",
		"urgent": "bg-danger",
	};
	const bs_class = status_colors[val];
	return (
		<div className="d-flex justify-content-center pt-1">
			<div title={getLabel(val)} className={bs_class + " status-bubble"}></div>
		</div>
	);
}

function ReportCell({ val, valid_date }) {
	const date_pieces = val.name.split('-');
	const date_string = `${date_pieces[2]}.${date_pieces[1]}.${date_pieces[0]}`;
	return <Link to={'/resource/report/' + val.uuid}>{date_string}</Link>
}

function getCell(key, val, is_super, valid_date, parent_uuid, resource, role) {
	if (!val) {
		return '-';
	}
	if ((key === 'client' || key === 'person') && Array.isArray(val)) {
		console.log('getCell with length', key, val);
		return val.map(item => {
			return (
				<div key={item.uuid}>
					{getCell(key, item, is_super, valid_date, parent_uuid, resource, role)}
				</div>
			);
		});
	} 

	if (isNull(val)) {
		return '-';
	}
	if (key === 'amount') {
		return <CHFString value={val} />
	}
	if (key === 'pdf') {
		return <a href={val.url}>herunterladen</a>;
	}
	if (key === 'status' && resource === 'instrument') {
		return <ReportStatusCell val={val} />;
	}
	if (key === 'status' && resource === 'offer') {
		return <ReportStatusCell val={val} />;
		//return getLabel(val);
	}
	if (key === 'reset_link') {
		return <a href={val}>Link</a>;
	}
	if (key === 'role') {
		return getLabel(val);
	}
	if (key === 'documents') {
		const documents_amount = JSON.parse(val).length;
		return documents_amount > 0 ? documents_amount : '-';
	}
	if (val.hasOwnProperty('name') && val.hasOwnProperty('uuid')) {
		if (key === 'report') {
			if (isNull(val.name) || isNull(val.uuid)) {
				if (['admin', 'super'].includes(role)) {
					return <Link to={'/resource/report/form?instrument=' + parent_uuid}>Erstellen</Link>;
				}
				return "-";
			}
			return <ReportCell val={val} valid_date={valid_date} />
		}
		if (isNull(val.name) || isNull(val.uuid)) {
			return '-';
		}
		if (!is_super && !role_resources[role].includes(key)) {
			return <span>{stripName(key, val.name)}</span>;
		}
		return <Link to={'/resource/' + key + '/' + val.uuid + `?valid_date=${valid_date}`}>{stripName(key, val.name)}</Link>
	}
	if (key === 'email') {
		return <a href={"mailto:" + val}>{val}</a>
	}
	if (key === 'employed') {
		const trans = {
			'yes': "Ja",
			'no': "Nein",
		};
		return trans[val];
	}
	if (key === 'date') {
		return menschifyDateSwiss(val);
	}
	if (key === 'tuning_period_in_months') {
		return getTuningPeriod(val);
	}
	if (val.uuid) {
		return <Link to={'/resource/' + key + '/' + val.uuid + `?valid_date=${valid_date}`}>{key}</Link>
	}
	if (val?.uuid === null) {
		return '-';
	}
	if (key === 'clients' && Array.isArray(val)) {
		return val.map(client => {
			return (
				<div key={client.uuid}>
					<Link to={'/resource/client/' + client.uuid + `?valid_date=${valid_date}`}>{client.name}</Link>
				</div>
			);
		});
	}
	//console.log('key toString', key, val);
	return val.toString();
}

export default function Table({ headers, data, role, is_super }) {
	const query = new URLSearchParams(useLocation().search);
	const now = new Date();
	const valid_date = query.get('valid_date') || `${now.getFullYear()}-${(now.getMonth() + 1).toString().padStart(2, '0')}-${now.getDate().toString().padStart(2, '0')}`;
	// console.log('table data', data);
	// console.log('table data ids', data.filter(row => row.id === 1445));
	const { resource } = useParams();
	const default_sort_list = {
		'client': 'number',
		'location': 'name',
		'brand': 'name',
		'model': 'name',
		'person': 'last_name',
		'offer': 'date',
		//'user': 'client',
		'user': 'name',
	};
	const default_sort = default_sort_list[resource] || "";
	const [sortedBy, setSortedBy] = useState(default_sort);
	const [sortDir, setSortDir] = useState(1);

	function handleSort(key) {
		if (key === sortedBy) {
			setSortDir(sortDir * -1);
		} else {
			setSortDir(1);
		}
		setSortedBy(key);
	}

	let sorted_data = data;
	if (sortedBy.length > 0) {
		sorted_data.sort((row_a, row_b) => {
			const field_a = row_a[sortedBy];
			const field_b = row_b[sortedBy];

			let as, bs;
			if (field_a === null) {
				as = ""
			} else if (isNumeric(field_a)) {
				as = field_a;
			} else if (isString(field_a)) {
				as = field_a.toUpperCase();
			} else if (field_a.name === null || !field_a.name) {
				as = "";
			} else {
				as = field_a.name.toUpperCase();
			}
			if (field_b === null) {
				bs = ""
			} else if (isNumeric(field_b)) {
				bs = field_b;
			} else if (isString(field_b)) {
				bs = field_b.toUpperCase();
			} else if (field_b.name === null || !field_b.name) {
				bs = "";
			} else {
				bs = field_b.name.toUpperCase();
			}

			const a = isNumeric(as) ? parseFloat(as) : as;
			const b = isNumeric(bs) ? parseFloat(bs) : bs;
			if (a < b) {
				return -1 * sortDir;
			}
			if (b < a) {
				return 1 * sortDir;
			}
			return 0;
		});
	}

	// const relations_in_filterable = getRelationsInFilterable();

	// const relations_in = Object.entries(relations_in_filterable).reduce((rel_in, entry) => {
	// 	if (entry[1].includes(resource)) {
	// 		rel_in.push(entry[0]);
	// 	}
	// 	return rel_in;
	// }, []);

	console.log({headers});
	console.log({sorted_data});

	return (
		<div className="table-responsive">
			<table className="table table-hover table-sm table-bordered">
				<thead className="thead-dark">
					<tr>
						{
							headers.map(key => (
								<th role="button" className={sortedBy === key ? ("sorted-header" + (sortDir === -1 ? "-reverse" : "")) : ""} onClick={e => handleSort(key)} key={key}>{getLabel(key, resource)}</th>
							))
						}
						{
							(['super', 'admin'].includes(role) || resource !== 'offer') && <th key="action-buttons">Aktionen</th>
						}
					</tr>
				</thead>
				<tbody>
					{
						sorted_data.map(row => (
							<tr
								key={row.id + (resource === 'user' ? uniqueId() : '')} className={is_super ? getOperationStyle(row.operation) : ""}>
								{
									headers.map(key => (
										<td data-header={key} key={key} className={isMono(key) ? "mono-font " : "" + (sortedBy === key ? "sorted-cell " : "") + (isTextRight(key) ? "text-right" : "")} >{
											getCell(key, row[key], is_super, resource === 'offer' ? row.report.name : valid_date, row.entity_uuid, resource, role)
										}</td>
									))
								}
								{
									(['super', 'admin'].includes(role) || resource !== 'offer') && (
										<td key="action-buttons">
											<div className="d-flex">
												<DetailsLink resource={resource} row={row} valid_date={valid_date} />
												{
													row.count_relations_in &&
													Object.entries(row.count_relations_in)
														.filter(([rel, value]) => value.count_in > 0)
														.map(([rel, value]) => <Link key={rel} className="btn btn-secondary btn-sm ml-1" to={`/resource/${rel}/?${resource}=${row.entity_uuid}&valid_date=${(resource === 'offer' ? row.report.name : valid_date)}`}>{getLabelPlural(rel)}</Link>)
												}
											</div>
										</td>
									)
								}
							</tr>
						))
					}
				</tbody>
			</table>
		</div>
	);
}