import { isString } from "lodash";

const all_resources = [
    //'user',
    'client',
    'location',
    'instrument',
    'person',
    'brand',
    'model',
    'finish2',
    'instrument_type',
    'offer'
];

const admin_resources = [
    'client', 'location', 'instrument', 'person', 'offer', 'brand', 'finish2', 'model',
];

const employee_resources = [
    'person', 'client', 'location', 'instrument', 'offer',
];

const client_resources = [
    'client', 'location', 'instrument', 'offer',
];

const role_resources = {
    super: all_resources,
    admin: admin_resources,
    employee: employee_resources,
    client: client_resources,
};

const labels = {
    // resources
    "location": "Standort",
    "person": {
        "client": "Schulleiter",
        "location": "Verantwortlich",
        "report": "Stimmer",
        "default": "Person",
        "instrument": "Stimmer"
    },
    "persons": "Verantwortlich",
    "client": "Kunde",
    "brand": "Marke",
    "finish2": "Ausführung",
    "instrument_type": "Typ",
    "model": {
        "instrument": "Marke & Modell",
        "default": "Modell",
    },
    "instrument": "Instrument",
    "report": "Bericht",

    // fields...
    // schon definierte werden hier nicht doppelt eingetragen
    // wenn z.B. person schon 'phone' definiert, müssen
    // wir es nicht noch für client definieren

    // fields: temporal
    "id": "id",
    "entity_uuid": "Referenz Nr.",
    "tx_from": "Transaktion von",
    "tx_to": "Transaktion bis",
    "valid_from": "Gültig von",
    "valid_to": "Gültig bis",
    "operation": "Operation",

    // fields: person
    "first_name": "Vorname",
    "middle_name": "Zweiter Vorname",
    "last_name": "Nachname",
    "email": "E-Mail",
    "phone": "Telefon",
    "mobile": "Mobile",
    "employed": "Angestellt",
    "yes": "Ja",
    "no": "Nein",
    "notes_internal": "Interne Notizen",

    // fields: client
    "number": "Nummer",
    "name": "Name",
    "street": "Strasse",
    "zip": "PLZ",
    "city": "Ort",

    // fields: instrument
    "finish": "Ausführung",
    "surface": "Oberfläche",
    "manufacturer_serial_number": "SN",
    "year_of_manufacture": "Jahr",
    "affiliation_type": "Zugeh.",
    "room": "Raum",
    "field_of_application": "Einsatzbereich",
    "dc_system_date_of_installation": "DC-Einbau",
    "dc_system_date_of_installation_month": "DC-Einbau Monat",
    "dc_system_date_of_installation_year": "DC-Einbau Jahr",

    // fields: report
    "tuning_period_in_months": "Stg.-Rythmus",
    "date": "Datum",
    "status": "Status",
    "notes": "Notizen",
    "tuning_done": "Stimmung ausgeführt?",
    "tone_pitch_on_arrival": "Tonhöhe vorgefunden",
    "tone_pitch_tuned": "Tonhöhe gestimmt",
    "dc_service_done": "DC-Service ausgeführt",
    "temperature_in_grad_celcius": "Temperatur in °C",
    "humidity_in_percent": "Luftfeuchtigkeit in %",
    "checklist": "Checkliste",
    "condition_of_surface": "Zustand der Oberfläche",
    "recommendations": "Empfehlungen",

    // checklist
    'soundboard': "Resonanzboden",
    'bridge': "Stege",
    'strings': "Saiten",
    'hammerhead': "Hammerköpfe",
    'whippens': "Hebeglieder",
    'axes': "Achsen",
    'damping': "Dämpfung",
    'pedals': "Pedale",
    'trimmings': "Garnierungen",
    'playweight': "Spielgewicht",
    'keycovering': "Tastenbeläge",

    // stati report
    "ok": "✔",
    "plannable": "Planbar",
    "urgent": "Dringend",

    // offer
    "offer": "Offerte",
    "offer_number": "Nummer",
    "amount": "Betrag in CHF",
    "open": "offen",
    "archived": "archiviert",
    "pdf": "PDF",
    "offer_status": "Offerten Status",

    // user
    "user": "Benutzer",
    "role": "Rolle",
    "super": "Super",
    "admin": "Admin",
    "client": "Kunde",
    "employee": "Angestellt",
    "password": "Passwort",
    "clients": "Kunden",
    "reset_link": "Passwort zurücksetzen",

    "documents": "Dokumente"
};

const plural_labels = {
    // resources
    "location": "Standorte",
    "person": "Personen",
    "client": "Kunden",
    "brand": "Marken",
    "finish2": "Ausführungen",
    "instrument_type": "Instrumenten-Typen",
    "model": "Modelle",
    "instrument": "Instrumente",
    "report": "Reports",
    "offer": "Offerten",
    "user": "Benutzer",
};

const plural_labels_acc = {
    // resources
    "location": "Standorten",
    "person": "Personen",
    "client": "Kunden",
    "brand": "Marken",
    "instrument_type": "Instrumenten-Typen",
    "model": "Modelle",
    "instrument": "Instrumenten",
    "offer": "Offerten"
};

const historyFields = [
    "id",
    "entity_uuid",
    "tx_from",
    "tx_to",
    "valid_from",
    "valid_to",
    "operation"
];

const rel_test_name = /[a-z0-9]+_rel_name$/;
const rel_test_property = /[a-z0-9]+_rel_property$/;
const rel_test_uuid = /[a-z0-9]+_uuid$/;
const test_entity_uuid = /^entity_uuid$/;
const rel_test_add = /person_reladd_[a-z0-9_]+$/;

function getRelName(key) {
    if (key !== 'entity_uuid' && rel_test_uuid.test(key)) {
        return key.slice(0, -5);
    }
    if (rel_test_name.test(key)) {
        return key.slice(0, -9);
    }
    if (rel_test_property.test(key)) {
        return key.slice(0, -13);
    }
    if (rel_test_add.test(key)) {
        return 'person';
    }
    return key;
}

const is_checklist_value_label = /check_([a-z]+)_value/;
const is_checklist_note_label = /check_([a-z]+)_note/;

function getLabel(key, resource) {
    let acc = "";
    if (is_checklist_value_label.test(key)) {
        acc = key.match(is_checklist_value_label)[1];
        //console.log(acc);
    } else if (is_checklist_note_label.test(key)) {
        //acc = key.match(is_checklist_note_label)[1];
        return "Notiz";
    } else {
        acc = getRelName(key);
    }
    const label = labels[acc];
    if (typeof label === 'undefined') {
        return "";
    }
    if (isString(label)) {
        return label;
    }
    if (resource) {
        return label[resource];
    }
    return label.default;
}

function getLabelPlural(key) {
    return plural_labels[key];
}

function getLabelPluralAcc(key) {
    return plural_labels_acc[key];
}

function getName(row) {
    if (row.name) {
        return row.name;
    }
    if (row.last_name) {
        return row.first_name + ' ' + (row.middle_name ? row.middle_name + ' ' : '') + row.last_name;
    }
    return '-';
}

function getHistoryFields() {
    return historyFields;
}

function isRelationUUID(field_name) {
    return rel_test_uuid.test(field_name) && !test_entity_uuid.test(field_name);
}

function isRelationName(field_name) {
    return rel_test_name.test(field_name);
}

function isRelationProperty(field_name) {
    return rel_test_property.test(field_name)
}

function isRelationAdd(field_name) {
    return rel_test_add.test(field_name);
}

function getRelationsIn(resource) {
    const relations = {
        'client': [
            'person'
        ],
        'person': [],
        'location': [
            'person',
            'client'
        ],
        'instrument_type': [],
        'brand': [],
        'model': [
            'brand'
        ],
        'instrument': [
            'instrument_type',
            'model',
            'location',
        ],
    }
    return Object.entries(relations).reduce((rels, rel) => {
        if (rel[1].includes(resource)) {
            rels.push(rel[0]);
        }
        return rels;
    }, []);
}

function getRelationsInFilterable() {
    const filterable = {
        'client': ['person'],
        location: ['client', 'person'],
        instrument: ['client', 'location'],
        //report: ['instrument', 'person'],
        'model': ['brand'],
        'offer': ['client', 'location', 'instrument']
    }
    return filterable;
}

function menschifyDate(date_string) {
    const date = new Date(date_string);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    const hour = date.getHours().toString().padStart(2, '0');
    const minute = date.getMinutes().toString().padStart(2, '0');
    const second = date.getSeconds().toString().padStart(2, '0');
    return `${day}.${month}.${year} ${hour}:${minute}:${second}`;
}

function menschifyDateShort(date_string) {
    console.log(date_string);
    const date = new Date(date_string);
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${month}.${year}`;
}

function menschifyDateSwiss(date_string) {
    if (!date_string || date_string === null || date_string === "") {
        return "∞";
    }
    const date_pieces = date_string.split('-');
    return `${date_pieces[2]}.${date_pieces[1]}.${date_pieces[0]}`;
}

const mono_columns = ['number', 'amount', 'offer_number', 'manufacturer_serial_number'];
const text_right_columns = ['number', 'amount', 'offer_number'];

function isMono(column) {
    return mono_columns.includes(column);
}

function isTextRight(column) {
    return text_right_columns.includes(column);
}


// todo
// fields are ordered in forms
const ordered_fields = {
    // client
    // location
    // instrument
};

const messages = {
    'ok': 'Ok',
    'error': 'Fehler',
}

function getMessage(msg) {
    return messages[msg];
}

function getTuningPeriod(period) {
    if (period === -1) {
        return "Nach Bedarf";
    }
    return period ? period + " Mt." : "-";
}

export { getTuningPeriod, getMessage, getLabel, getLabelPlural, getLabelPluralAcc, getName, getHistoryFields, isRelationUUID, isRelationName, isRelationProperty, isRelationAdd, getRelName, getRelationsIn, getRelationsInFilterable, menschifyDate, menschifyDateShort, menschifyDateSwiss, isMono, isTextRight, role_resources }

